import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { FaArrowRight } from "react-icons/fa6";
import Loader from "../components/Loader";

import { fetchContent } from "../content/FetchContent.js";

const Welcome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({
    video: "",
  });

  const urlParams = new URLSearchParams(window.location.search);
  const inviteId = urlParams.get("invite-id");
  const loggedinUser = useSelector((state) => state.auth.user);
  const [muteButton, setMuteButton] = useState(false);
  const [showPauseButton, setShowPauseButton] = useState(false);

  useEffect(() => {
    const verifyUser = async () => {
      if (inviteId === null && loggedinUser != null) return;
      await signIN(inviteId);
    };

    verifyUser();
  }, []);

  const signIN = async (accessToken, refreshToken = "") => {
    try {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      const resp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/user-info`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            authorization: `Bearer ${accessToken}`,
          },
        },
      );
      dispatch({
        type: "SET_USER",
        payload: resp.data.user,
      });

      const { success, content } = await fetchContent(
        "welcome",
        resp.data.user.brand,
      );
      if (success) {
        setData({ video: content.video });
      }

      toast.success("Email verified successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Invitation link is invalid");
      navigate("/signin");
    } finally {
      setLoader(false);
    }
  };

  const togglePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setMuteButton(true);
    }
  };
  const togglePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setMuteButton(false);
    }
  };
  return (
    <div
      className="animate-fade-in-1s"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
        height: "calc(100vh - 50px)",
        padding: "20px 0",
      }}
    >
      <div
        style={{
          flex: 1,
          width: "100%",
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loader && (
          <div className="absolute backdrop-blur-sm w-full z-20 h-screen">
            <Loader />
          </div>
        )}
        <div>
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            Welcome to Jawaab
          </h5>
          <p className="mb-3 font-normal text-gray-600 dark:text-gray-400">
            Navigating Health Solutions: Join the Conversation on Jawaab
          </p>
        </div>

        <div
          style={{ maxWidth: "60%", position: "relative", marginTop: "20px" }}
          onMouseEnter={() => setShowPauseButton(true)}
          onMouseLeave={() => setShowPauseButton(false)}
        >
          {data.video && (
            <video
              ref={videoRef}
              autoPlay
              loop
              src={data.video}
              playsInline={true}
              // onCanPlay={() => setLoader(false)}
              onError={(e) => console.log("error", e)}
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "300px",
              }}
            />
          )}
          {!muteButton ? (
            <button onClick={() => togglePlay()} style={videoButtonStyle}>
              Click to start
            </button>
          ) : (
            showPauseButton && (
              <button onClick={() => togglePause()} style={videoButtonStyle}>
                Click to stop
              </button>
            )
          )}
        </div>

        <p
          className="font-normal text-sm text-gray-700 dark:text-gray-400 italic"
          style={{
            maxWidth: "45em",
            margin: "20px auto",
            overflow: "hidden",
            textAlign: "center",
            padding: "0px 10px",
          }}
        >
          “Unlock the potential of collaborative healthcare with Jawaab. This
          platform serves as a nexus for doctors and pharmacy companies to
          exchange insights, discuss medications, and streamline communication.”
        </p>

        <Link
          className="inline-flex items-center"
          to={`/choose-avatar`}
          style={{
            width: "100%",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <button style={nextButtonStyle}>
            Next <FaArrowRight />
          </button>
        </Link>
      </div>

      <div style={{ alignSelf: "center" }}>
        <p>Powered by Jawaab</p>
        <span style={{ fontSize: ".7rem" }}>
          A ValueDo HCP Engagement Platform
        </span>
      </div>
    </div>
  );
};

const videoButtonStyle = {
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  bottom: "10px",
  padding: "5px 10px",
  cursor: "pointer",
  border: "none",
  background: "rgba(255, 255, 255, 0.7)",
  borderRadius: "5px",
  fontSize: "small",
};

const nextButtonStyle = {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  background: "#305095",
  color: "white",
  fontWeight: "bold",
  padding: "0.5rem 1rem",
  borderRadius: "0.5rem",
  justifyContent: "center",
  textAlign: "center",
};

export default Welcome;
